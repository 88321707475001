// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <h1 className='NamePlate'>Hi, my name's Raffael.</h1>

      <h2>I recently graduated from Yale university, where I majored in Computer Science. I'm currently applying to Master's programs, and I'd love to hear your advice. On the side, I read, write, game, and am learning to build thigns for fun and for friends.</h2>

      <div>
        <h3> Things I'm working on:</h3>
        <div className="projects">
          <ul>
            <li>hi</li>
            <li>hi</li>
            <li>hi</li>
          </ul>
        </div>
      </div>

    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
